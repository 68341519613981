// @flow strict
import React from 'react';
import Author from './Author';
import Menu from './Menu';
import MovableSidebarContent from '../MovableSidebarContent';
import styles from './Sidebar.module.scss';
import { useSiteMetadata } from '../../hooks';

const Sidebar = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles['sidebar']}>
      <div className={styles['sidebar__inner']}>
        <Author author={author} />
        <Menu />
        <MovableSidebarContent desktop />
      </div>
    </div>
  );
};

export default Sidebar;
