// @flow
import { useSiteMetadata } from '../../hooks';
import React from 'react';

import Contacts from '../Contacts';
import Copyright from '../Copyright';
import DisplayIf from '../DisplayIf';

type Props = {
  +mobile?: boolean,
  +desktop?: boolean,
};

const MovableSidebarContent = ({
    mobile,
    desktop,
}: Props) => {
    const { author, copyright } = useSiteMetadata();
    return (
        <DisplayIf mobile={mobile} desktop={desktop}>
            <Contacts contacts={author.contacts} />
            <Copyright copyright={copyright} />
        </DisplayIf>
    );
};

export default MovableSidebarContent;
