// @flow strict

const _ = require('lodash');
import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import { PAGINATION } from '../../constants';
import styles from './Pagination.module.scss';

type Props = {
  category: string,
  tag: string,
  currentPage: number,
  numPages: number,
  prevPagePath: string,
  nextPagePath: string,
  hasNextPage: boolean,
  hasPrevPage: boolean
};

const cx = classNames.bind(styles);

const Pagination = ({
  category,
  tag,
  currentPage,
  numPages,
  prevPagePath,
  nextPagePath,
  hasNextPage,
  hasPrevPage
}: Props) => {
  const prevClassName = cx({
    'pagination__prev-link': true,
    'pagination__prev-link--disable': !hasPrevPage
  });

  const nextClassName = cx({
    'pagination__next-link': true,
    'pagination__next-link--disable': !hasNextPage
  });

  const tagPath = (tag, page) => (page <= 1 ? `/tag/${tag}/` : `/tag/${tag}/page/${page-1}/`);
  const categoryPath = (category, page) => (page <= 1 ? `/category/${category}` : `/category/${category}/page/${page-1}/`);
  const pagePath = page => (page <= 1 ? '/' : `/page/${page-1}/`);

  const path = (page, tag, category) => {
    if (tag !== undefined) {
      return tagPath(_.kebabCase(tag), page);
    }
    if (category !== undefined) {
      return categoryPath(_.kebabCase(category), page);
    }
    return pagePath(page);
  }

  // TODO: Not sure why numPages is undefined sometimes for categories
  if (numPages === undefined) {
    numPages = 1;
  }

  let pageNumbers = [];
  // If there are <= 7 pages, we'll never need an ellipsis.
  if (numPages <= 7) {
    for (let i = 1; i <= numPages; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage <= 4) {
    // 1 2 3 4 5 ... n
    pageNumbers = [1, 2, 3, 4, 5, '…', numPages];
  } else if (currentPage < numPages - 3) {
    // 1 ... c-1 c c+1 ... n
    pageNumbers = [1, '…', currentPage - 1, currentPage, currentPage + 1, '…', numPages];
  } else {
    // 1 ... n-4 n-3 n-2 n-1 n
    pageNumbers = [1, '…', numPages - 4, numPages - 3, numPages - 2, numPages - 1, numPages];
  }

  return (
    <div className={styles['pagination']}>
      <div className={styles['pagination__prev']}>
        <Link rel="prev" to={hasPrevPage ? prevPagePath : '/'} className={prevClassName}>{PAGINATION.PREV_PAGE}</Link>
      </div>
      <ul className={styles['pagination__list-container']}>
        {pageNumbers.map((pn, i) => (
          <li key={`pagenum-${i}`}>
            {pn === '…' ? (
              <p>…</p>
            ) : (
              <Link to={path(pn, tag, category)} className={(pn-1) === currentPage ? styles['selected'] : ''}>
                {pn}
              </Link>
            )}
          </li>
        ))}
      </ul>
      <div className={styles['pagination__next']}>
        <Link rel="next" to={hasNextPage ? nextPagePath : '/'} className={nextClassName}>{PAGINATION.NEXT_PAGE}</Link>
      </div>
    </div>
  );
};

export default Pagination;
